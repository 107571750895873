<template>
  <div class="">
    <div class="row">
      <div class="col-12 col-md-5">
        <documents-send-mail-form
          :documentData="salesInvoice"
          :dispatchUrl="'salesInvoices/sendMail'"
          :toggleMailModal="toggleMailModal"
          :mailInformation="mailInformation"
        />
      </div>
      <div
        class="col-12 col-md-7"
        style="max-height: 100%;"
      >
        <sales-invoice-view-details
          :showHeader="false"
          :salesInvoice="salesInvoice"
          @salesInvoiceItemsUpdated="get"
        />
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <el-button type="danger" @click="toggleMailModal">
        {{ $t("COMMON.CANCEL") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { Button, Option, Select } from "element-ui";
import "flatpickr/dist/flatpickr.css";
import formMixin from "@/mixins/form-mixin";
import SalesInvoiceViewDetails from "./SalesInvoiceViewDetails.vue";
import DocumentsSendMailForm from "@/components/DocumentsSendMailForm";

export default {
  layout: "DashboardLayout",
  name: "sales-invoice-mail-modal-content",

  components: {
    DocumentsSendMailForm,
    [Select.name]: Select,
    [Option.name]: Option,
    SalesInvoiceViewDetails,
    [Button.name]: Button,
  },

  mixins: [formMixin],
  props: {
    salesInvoice: {
      default: () => {},
    },
    toggleMailModal: {
      type: Function,
    },
    get: {
      type: Function,
    },
    loading: {
      default: () => true,
    },
  },

  data() {
    return {
      submitting: false,
      mailInformation: {
        organization: {},
        recipient: {},
        user: {},
      },
    };
  },

  created() {},
  mounted() {
    this.mailInformation = {
      organization: this.salesInvoice.organization,
      subject: this.$t("COMMON.EMAIL_INFOS_SUBJECT_INVOICE", {
        organization: this.salesInvoice.organization?.name,
        seq_number: this.salesInvoice.seq_number,
      }),
      recipient: {
        username: this.salesInvoice?.recipient?.customer_name,
        email: this.salesInvoice?.recipient?.email,
      },
    };
  },

  methods: {},

  watch: {
    salesInvoice(salesInvoice) {
      this.mailInformation = {
        organization: salesInvoice.organization,
        subject: this.$t("COMMON.EMAIL_INFOS_SUBJECT_INVOICE", {
          organization: salesInvoice.organization?.name,
          seq_number: salesInvoice.seq_number,
        }),
        recipient: {
          username: salesInvoice?.recipient?.customer_name,
          email: salesInvoice?.recipient?.email,
        },
      };
    },
  },
};
</script>
