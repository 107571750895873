<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ salesInvoice.code }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(salesInvoice.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("SALES_INVOICES.EXPIRATION_TIME") }}
          </dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(salesInvoice.expiration_time) }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.CAFETERIA") }}</dt>
          <dd class="col-sm-8">
            <cafeteria :cafeteria="salesInvoice.cafeteria" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ESTABLISHMENT") }}</dt>
          <dd class="col-sm-8">
            <establishment :establishment="salesInvoice.establishment" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.SERVICECENTER") }}</dt>
          <dd class="col-sm-8">
            <service-center :serviceCenter="salesInvoice.serviceCenter" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="salesInvoice.allowedLocations" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="salesInvoice.organization" />
          </dd>
        </dl>

        <dl class="row" v-if="salesInvoice.salesInvoice">
          <dt class="col-sm-4">{{ $t("COMMON.SALES_ORDER") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="salesInvoice.salesInvoice" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.RECIPIENT") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="salesInvoice.recipient" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8">
            <sales-invoice-status-badge :salesInvoice="salesInvoice" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.HAS_TAXES") }}
          </dt>
          <dd class="col-sm-8">
            <icon-check :checked="!salesInvoice.has_no_taxes" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.PAYMENT_TERMS") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="salesInvoice.paymentTerm" />
          </dd>
        </dl>

        <dl class="row" v-if="salesInvoice.sourceWarehouse">
          <dt class="col-sm-4">{{ $t("COMMON.WAREHOUSE") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="salesInvoice.sourceWarehouse" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TOTAL") }}</dt>
          <dd class="col-sm-8" v-if="salesInvoice.pricing">
            <span> {{ $formatCurrency(salesInvoice.pricing.total) }} </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TOTAL_PAIED") }}</dt>
          <dd class="col-sm-8">
            <span>
              {{
                $formatCurrency(
                  salesInvoice.total_paied ? salesInvoice.total_paied : 0
                )
              }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4" v-if="salesInvoice.excerpt">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd class="col-sm-8" v-if="salesInvoice.excerpt">
            <div v-html="salesInvoice.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4" v-if="salesInvoice.terms_conditions">
            {{ $t("COMMON.TERMS_CONDITIONS") }}
          </dt>
          <dd class="col-sm-8" v-if="salesInvoice.terms_conditions">
            <div v-html="salesInvoice.terms_conditions"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4" v-if="salesInvoice.description">
            {{ $t("COMMON.DESCRIPTION") }}
          </dt>
          <dd class="col-sm-8" v-if="salesInvoice.description">
            <div v-html="salesInvoice.description"></div>
          </dd>
        </dl>

        <div class="col-12 text-center mb-5 mt-5">
          <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>
        </div>

        <dl class="row" v-if="salesInvoice.billing_entity_type != 'N/A'">
          <dt class="col-sm-4">{{ $t("COMMON.LASTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ salesInvoice.billing_lastname }}
          </dd>
        </dl>

        <dl class="row" v-if="salesInvoice.billing_entity_type != 'N/A'">
          <dt class="col-sm-4">{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ salesInvoice.billing_firstname }}
          </dd>
        </dl>

        <dl class="row" v-if="salesInvoice.billing_entity_type != 'N/A'">
          <dt class="col-sm-4">{{ $t("COMMON.COMPANY_NAME") }}</dt>
          <dd class="col-sm-8">
            {{ salesInvoice.billing_company_name }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
          <dd class="col-sm-8">
            {{ salesInvoice.billing_country }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
          <dd class="col-sm-8">
            {{ salesInvoice.billing_state }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
          <dd class="col-sm-8">
            {{ salesInvoice.billing_city }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd class="col-sm-8">
            {{ salesInvoice.billing_zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ salesInvoice.billing_address }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ salesInvoice.billing_email }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import Cafeteria from "@/components/Cafeteria.vue";
import Establishment from "@/components/Establishment.vue";
import ServiceCenter from "@/components/ServiceCenter.vue";
import IconCheck from "@/components/IconCheck.vue";
import SalesInvoiceStatusBadge from "./SalesInvoiceStatusBadge.vue";

export default {
  name: "sales-invoice-view-global",

  components: {
    SalesInvoiceStatusBadge,
    Cafeteria,
    Establishment,
    ServiceCenter,
    IconCheck,
  },

  props: ["salesInvoice"],

  data() {
    return {
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    salesInvoice(salesInvoice) {},
  },
};
</script>
